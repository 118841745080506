import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const Two = () => {
  return (
    <section className={styles.one}>
      <H1 text="Receipt"></H1>

      <div className={styles.grid}>
        <div>
          <span>
            Thank you for subscribing, your account is active and ready to use.
          </span>
        </div>
      </div>
    </section>
  )
}

export default Two
