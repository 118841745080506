import React from "react"
import { Link } from "gatsby"
import logo from "../../img/logo.svg"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileChartLine, faFileUser } from "@fortawesome/pro-light-svg-icons"

const One = () => {
  const items = [
    {
      id: "/sub",
      name: "Reports",
      icon: faFileChartLine,
    },
    {
      id: "/sub/account",
      name: "My Account",
      icon: faFileUser,
    },
  ]

  return (
    <div className={styles.containerDesktop}>
      <div className={styles.left}>
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="ETF Stock Report Logo" />
        </Link>
      </div>

      <div className={styles.right}>
        {items.map(item => {
          return item.icon ? (
            <Link to={`${item.id}`} className={`btn btn-icon`} key={item.id}>
              <div>
                <FontAwesomeIcon icon={item.icon} size="lg" />
                <span>{item.name}</span>
              </div>
            </Link>
          ) : null
        })}
      </div>
    </div>
  )
}

export default One
