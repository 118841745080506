import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"
import { API_URL, setValToLS } from "../../utils/helpers"
import Checkbox from "../../components/Checkbox"
import Submit from "../../components/Submit"
import Alert from "../../components/Alert"
import * as styles from "./styles.module.css"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Three = ({ id }) => {
  const [details, setDetails] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })
  const [names, setNames] = useState({})

  const formElement = useRef(null)

  useEffect(() => {
    console.log("useEffect called")

    const init = async () => {
      const url = new URL(`${API_URL}/receiptInit`)
      const params = {
        id,
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json) {
          setDetails({ ...json })
          setAlert({})
          setValToLS("etfsrToken", json.token, true)
        }
      } catch (error) {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    }

    init()
  }, [id])

  const handleUpdate = (name, value) => {
    if (name === "remember") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (names.remember) {
      setValToLS("etfsrId", id)
    }
    navigate("/sub")
  }

  return (
    <section className={styles.three}>
      {alert.type === "working" ? (
        <Alert data={alert} />
      ) : (
        <form
          method="get"
          action="/"
          onSubmit={e => handleSubmit(e)}
          ref={formElement}
        >
          <p>
            Thanks again for your subscription, a copy of this receipt has also
            been emailed to {details.email}. For any questions, please contact
            us at{" "}
            <a
              href="mailto:celestialcreativesolutions@gmail.com"
              className="text"
            >
              celestialcreativesolutions@gmail.com
            </a>{" "}
            or check out our <Link to="/faqs">FAQs</Link> for help getting
            started.
          </p>

          <p>
            Access the subscribers area by clicking the button below, check the
            remember me box and you will remained signed in on this device
            (unless you sign out).
          </p>

          <div className="one" style={{ marginTop: "25px" }}>
            <table>
              <thead>
                <tr>
                  <th>Qty</th>
                  <th className="left">Description</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="center">{details.items[0].qty}</td>
                  <td className="left">
                    <div className={styles.item}>
                      <span>{details.items[0].name}</span>
                      <small>{details.items[0].descr}</small>
                    </div>
                  </td>
                  <td className="right">{details.items[0].total}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th className="right" colSpan="2">
                    TOTAL:
                  </th>
                  <td className="right">{details.total}</td>
                </tr>
                <tr>
                  <td className="right" colSpan="2">
                    {details.payment.descr}
                  </td>
                  <td className="right">{details.payment.amt}</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="one">
            <Checkbox
              name="remember"
              label="Check to remember your sign in on this device"
              reqd={false}
              click={handleUpdate}
              checked={names.remember || false}
            />
          </div>

          <div className="msgSubmit">
            <div>
              <Submit
                name="Go to the Subscriber area"
                icon={faChevronCircleRight}
              />
            </div>
          </div>
        </form>
      )}
    </section>
  )
}

export default Three
