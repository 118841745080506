import React, { useState, useEffect, Fragment } from "react"
import { navigate } from "gatsby"
import Helmet from "../components/Helmet"
import One from "../receiptPage/One"
import Two from "../receiptPage/Two"
import Three from "../receiptPage/Three"
import Footer from "../components/Footer"

const ReceiptPage = ({ location }) => {
  const [id, setId] = useState(0)

  useEffect(() => {
    console.log("useEffect called")
    if (location && location.state && location.state.id) {
      setId(location.state.id)
    } else {
      navigate("/")
    }
  }, [location])

  return (
    <Fragment>
      {id ? (
        <Fragment>
          <Helmet
            title="Receipt"
            descr="Thank you for subscribing."
            slug="/receipt"
          />
          <One />
          <Two />
          <Three id={id} />
          <Footer />
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default ReceiptPage
